<template>
  <div id="manager-billing-list">
    <!-- list filters -->
    <v-card>
      <v-card-title> Manager Billing list </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- status filter -->
        <v-col cols="12" :sm="3">
          <v-select
            class="billing-status-filter"
            v-model="statusFilter"
            placeholder="Select Status"
            :items="billingStatus"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" :sm="3">
          <v-dialog ref="dialog" v-model="monthFilterModal" :return-value.sync="monthFilter" persistent width="290px">
            <template #activator="{ on, attrs }">
              <v-text-field
                class="billing-month-filter"
                v-model="monthFilter"
                label="Select Period"
                outlined
                dense
                clearable
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="monthFilter" type="month" scrollable @input="$refs.dialog.save(monthFilter)">
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" sm="3">
          <v-autocomplete
            class="billing-manager-name-filter"
            v-model="managerFilter"
            :items="managers"
            item-value="id"
            item-text="fullName"
            dense
            filled
            outlined
            clearable
            hide-details
            label="Manager name"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <v-row v-if="selectedRows.length > 0">
        <v-spacer></v-spacer>
        <v-col cols="3">
          <v-edit-dialog large persistent @save="updateStatus()">
            <v-btn color="primary" class="billing-list-update-item-status mb-4 me-4 mt-4 ms-4">
              <span>Update status</span>
            </v-btn>
            <template #input>
              <div class="mt-4 title">Update Status</div>
              <v-select
                class="billing-select-new-status"
                v-model="status"
                :items="billingStatus"
                item-text="title"
                item-value="value"
                placeholder="Select Status"
                label="Status"
              ></v-select>
            </template>
          </v-edit-dialog>
        </v-col>
        <v-col cols="3">
          <v-btn color="primary" class="billing-delete-invoices mb-4 me-4 mt-4 ms-4" @click="deleteInvoices">
            <span>Delete invoices</span>
          </v-btn>
        </v-col>
      </v-row>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-spacer></v-spacer>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :options.sync="options"
        :headers="tableColumns"
        :items="billingListTable"
        :server-items-length="totalBillingListTable"
        :item-class="billingItemClass"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [30, 45, 50] }"
        :show-select="true"
      >
        <template #[`item.id`]="{ item }">
          <div>{{ item.id.split('-')[0] }}</div>
        </template>
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="resolveBillingStatusVariant(item.status)"
            :class="`${resolveBillingStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            <span>{{ item.status }}</span>
          </v-chip>
        </template>

        <template #[`item.fullName`]="{ item }">
          <div>{{ item.reseller ? item.reseller.fullName : '' }}</div>
        </template>

        <template #[`item.totalAmount`]="{ item }">
          <div>{{ item.totalAmountConverted ? item.totalAmountConverted : item.totalAmount }} {{ item.currency }}</div>
        </template>

        <template #[`item.createdAt`]="{ item }">
          <div> {{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template #activator="{ on, attrs }">
              <v-btn class="billing-list-item-show-more-actions" icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'manager-billing-view', params: { id: item.id } }">
                <v-list-item-title class="billing-list-item-detail-invoice">
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="billing-list-item-delete-invoice" @click="deleteInvoice(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template #footer.page-text>
          Total amount: {{ totalAmounts.eur.toFixed(2) }} EUR / {{ totalAmounts.usd.toFixed(2) }} USD
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiPlus, mdiFileDocumentOutline, mdiSendOutline, mdiDeleteOutline } from '@mdi/js';
import useManagerBillingsList from './useManagerBillingsList';
import { ref } from '@vue/composition-api';

export default {
  setup() {
    const {
      status,
      billingStatus,
      managers,
      billingListTable,
      tableColumns,
      statusFilter,
      monthFilter,
      monthFilterModal,
      loading,
      options,
      billingTotalLocal,
      selectedRows,
      totalBillingListTable,
      managerFilter,
      fetchBillings,
      deleteInvoices,
      deleteInvoice,
      updateStatus,
      resolveBillingStatusVariant,
      resolveBillingStatusName,
      resolveBillingTotalIcon,
      totalAmounts,
      isSendingInProcess,
    } = useManagerBillingsList();

    const isAddNewInvoiceSidebarActive = ref(false);

    const billingItemClass = () => {
      return 'billing-item';
    };

    return {
      status,
      billingStatus,
      managers,
      billingListTable,
      tableColumns,
      statusFilter,
      monthFilter,
      monthFilterModal,
      managerFilter,
      totalBillingListTable,
      loading,
      options,
      billingTotalLocal,
      isAddNewInvoiceSidebarActive,
      selectedRows,
      deleteInvoices,
      deleteInvoice,
      resolveBillingStatusVariant,
      resolveBillingStatusName,
      resolveBillingTotalIcon,
      fetchBillings,
      updateStatus,
      billingItemClass,
      totalAmounts,
      icons: {
        mdiFileDocumentOutline,
        mdiPlus,
        mdiDotsVertical,
        mdiSendOutline,
        mdiDeleteOutline,
      },
      isSendingInProcess,
    };
  },
};
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
